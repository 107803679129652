$('[data-toggle="search"]').click(function(){
	var bg = document.createElement('div');

	$(bg).addClass('bg-buscas');
	$(bg).click(function() {
		$('.buscas').removeClass('show');

		$('.bg-buscas').fadeOut(600, function(){
			$(this).remove();
		});
	});

	TOPO.appendChild(bg);

	$('.buscas').addClass('show');
	$(bg).fadeIn(600);
	$(this).blur();
	$('.buscas .form-control').focus();
});
