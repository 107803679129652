var animar = (function(){
	var jaAnimou = false;

	dispararAnimacao();

	$(window).scroll(function() {
		if(jaAnimou) return;

		setTimeout(function() {
			jaAnimou = false;
		}, 100);

		dispararAnimacao();
	});

	function dispararAnimacao () {
		$('[data-animate]').each(function() {
			var offset = $(this).data('offset') || 50;

			if(this.getBoundingClientRect().top <= (window.innerHeight - offset)){
				var animation = $(this).data('animate');

				$(this).addClass(animation)
					.removeAttr('data-animate');
			}
		});
	}
})();
