var fxTopo = (function(){
	var jaFx = false;

	if(midiaSize === 'lg' || midiaSize === 'xl') {

		verify();

		$(window).scroll(function() {
			if(jaFx) return;

			setTimeout(function(){
				jaFx = false;
			}, 100);

			verify();
		});
	}

	function verify () {
		var w 		= $(window).scrollTop();
		var fh		= $('.faixa-topo').outerHeight();
		var nav 	= $('.nav-area');
		var topo 	= $('.topo');
		var th 		= topo.outerHeight();

		if(w >= fh) {
			topo.css('height', th);

			nav.addClass('fx');

			if(w >= th) {
				nav.addClass('mini-fx');
			}else{
				nav.removeClass('mini-fx');
			}

		}else{
			nav.removeClass('fx')
		}
	}
})();
