var lazyBG = (function() {
	var jaLazy = false;

	verify();

	$(window).scroll(function(){
		if(jaLazy) return;

		setTimeout(function(){
			jaLazy = false;
		}, 100);

		verify();
	});

	function verify () {
		$('[data-lazy-bg]').each(function(){
			if(this.getBoundingClientRect().top <= (window.innerHeight + 200)) {
				var bg = $(this).data('lazy-bg');

				$(this).css('background-image', `url(${bg})`)
					.removeAttr('data-lazy-bg');
			}
		});
	}
})();
