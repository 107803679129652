$('[data-toggle="menu"]').click(function(e){
	e.preventDefault();

	if(midiaSize == 'xs' || midiaSize == 'sm' || midiaSize == 'md'){


		let bgMenu = document.createElement('div');

		$(bgMenu).addClass('bg-menu');
		$(bgMenu).click(function() {

			$('.main-topo').removeClass('open');

			$('.bg-menu').fadeOut(600, function(){
				$(this).remove();
			});
		});

		TOPO.appendChild(bgMenu);

		$('.main-topo').addClass('open');
		$(bgMenu).fadeIn(600);

		$(this).blur();
	}
});
