$('.filtro-produtos a').click(function(e){
	e.preventDefault();

	var dataRout 	= $(this).attr('href');
	var template 	= $(this).data('template');
	var tpl 		= $(template).html();
	var target		= $(this).data('target');

	$('.filtro-produtos a').removeClass('active')
	$(this).addClass('active');

	$.ajax({
		url:  dataRout,
		dataType: 'json'
	}).done(function(data) {

		var owlStage = document.querySelector(`${target} .owl-stage`);

		owlStage.innerHTML = '';

		var  content ='';

		$(target).refreshOwl(data.length);

		data.forEach(function(produto){
			var p = tpl.replace(/{{image.src}}/g, produto.image.src)
					   .replace(/{{image.width}}/g, produto.image.width)
					   .replace(/{{image.height}}/g, produto.image.height)
					   .replace(/{{image.alt}}/g, produto.image.alt)
					   .replace(/{{nome}}/g, produto.nome)
					   .replace(/{{descricao}}/g, produto.descricao)

			content += p;
		});

		$(target)
			.trigger('replace.owl.carousel', content)
			.trigger('refresh.owl.carousel');

		Lazyimage.register(`${target} lazy-image`)
	})
});
