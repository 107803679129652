function onOwlCarouselReady(){

	$.fn.myOwl = function() {
		return this.each(function(){
			let responsive 		= {};
			let owl 			= $(this);
			let childrenLenght 	= owl.children().length;
			let autoplay 		= owl.data('autoplay') 	|| false;
			let margin 			= owl.data('margin') 	|| 0;
			let loop 			= owl.data('loop') 		|| false;
			let nav 			= owl.data('nav') 		|| false;
			let prev 			= owl.data('prev')  	|| 'fas fa-chevron-left';
			let next 			= owl.data('next')  	|| 'fas fa-chevron-right';
			let navText 		= [
				`<i class="${prev}" aria-label="Prev"></i><span class="sr-only">Prev</span>`,
				`<i class="${next}" aria-label="Next"></i><span class="sr-only">Next</span>`
			];
			const hasloop 	= midia => {return loop ? (childrenLenght > midia) : false};

			responsive.xs = owl.data('xs') || 1;
			responsive.sm = owl.data('sm') || responsive.xs;
			responsive.md = owl.data('md') || responsive.sm;
			responsive.lg = owl.data('lg') || responsive.md;
			responsive.xl = owl.data('xl') || responsive.lg;

			owl.owlCarousel({
				autoplay 	: autoplay,
				nav 		: nav,
				navText 	: navText,
				responsive 	: {
					0   : {
						items  : responsive.xs,
						loop   : hasloop(responsive.xs),
						margin : margin
					},
					576 : {
						items  : responsive.sm,
						loop   : hasloop(responsive.sm),
						margin : margin
					},
					768 : {
						items  : responsive.md,
						loop   : hasloop(responsive.md),
						margin : margin
					},
					992 : {
						items  : responsive.lg,
						loop   : hasloop(responsive.lg),
						margin : margin
					},
					1200 : {
						items  : responsive.xl,
						loop   : hasloop(responsive.xl),
						margin : margin
					}
				}
			});

		});
	}

	$.fn.refreshOwl = function(childLenght) {
		return this.each(function() {
			var c 			= childLenght;
			var owl 		= $(this).data('owl.carousel')
			var options 	= owl.options;
			var responsive 	= options.responsive;

			for(let m in responsive) {
				var d = responsive[m];

				if(c <= d.items) {
					d.loop = false;
				}
			}

			console.dir(responsive)
		});
	}

	$('[data-owl]').each(function(){
		let direction 	= $(this).data('owl');
		let target 		= $(this).attr('href');
		let self  		= $(this);
		let children	= $(target).length;

		$(this).click(function(e){
			e.preventDefault();


			if(!$(this).hasClass('disabled')){
				$(target).trigger(`${direction}.owl.carousel`);
			}
		});
	});


	$('.owl-carousel').on('initialized.owl.carousel', function(e){
		let prev = $(this).find('.owl-prev');
		let next = $(this).find('.owl-next');

		prev.addClass('oi');
	});

	$('.owl-carousel').myOwl();

	$('.owl-carousel').each(function(){
		let id 				= this.id;
		let prev 			= $(this).find('.owl-prev');
		let next 			= $(this).find('.owl-next');
		let externalCtrls 	= $(`a[href="#${id}"]`);
		let disabledLenght	= 0;


		function check () {
			if(prev.hasClass('disabled')){
				externalCtrls.each(function(){

					if($(this).data('owl') === 'prev'){
						$(this).addClass('disabled');
					}else{
						$(this).removeClass('disabled');
					}

				});

				disabledLenght++
			}

			if(next.hasClass('disabled')){
				externalCtrls.each(function(){

					if($(this).data('owl') === 'next'){
						$(this).addClass('disabled');
					}else{
						$(this).removeClass('disabled');
					}

				});
				disabledLenght++
			}

			ready();
		}

		function ready () {
			if(disabledLenght == 2){
				externalCtrls.removeClass('ready');
			}else{
				externalCtrls.addClass('ready');
			}
		}

		check();

		$(this).on('changed.owl.carousel', function(){
			disabledLenght = 0;
			check();
		});

	});
}
