var progressBar = (function() {
	var jaProgress = false;

	verify();

	$(window).scroll(function() {
		if(jaProgress) return;

		setTimeout(function() {
			jaProgress = false;
		}, 100);

		verify();
	});

	function verify() {
		$('.progress-area:not(.active)').each(function() {
			var rect = this.getBoundingClientRect();
			var targetPos = window.innerHeight - $(this).outerHeight();

			if(rect.top <= targetPos){
				var mount = $(this).data('mount');
				var mountText = $(this).find('.mount');

				$(this).addClass('active');
				$(this).find('.progresso').css('width', `${mount}%`);

				$({countNum: mountText.text()}).animate({
					countNum : mount
				}, {
					duration 	: 1000,
					easing 		: 'linear',
					step 		: function() {
						mountText.text(`${Math.floor(this.countNum)}%`)
					},
					complete 	: function() {
						mountText.text(`${this.countNum}%`);
					}
				})
			}
		});
	}
})();
